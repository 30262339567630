// Table colors

.isLate {
  background: rgba(255, 0, 0, 0.1) !important;
  &:hover {
    background: rgba(255, 0, 0, 0.2) !important;
  }
}
.shouldAlert{
  background: rgba(255, 192, 0, 0.1) !important;
  &:hover {
    background: rgba(255, 192, 0, 0.2) !important;
  }
}